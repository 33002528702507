angular = require('angular');

var deleteFromArray = function(array, c) {
  var index = array.indexOf(c);
  array.splice(index, 1);
};

angular.module('woin-character')
  .filter('isUniversalExploit', function() {
      return function(items) {
        var filtered = [];
        angular.forEach(items, function(i) {
          if (i.universal === true) {
            filtered.push(i);
          }
        });
        return filtered;
      }
  })
  .filter('meetsPrerequisites', ['ExploitService', function(ExploitService) {
    return function(items, character) {
      var filtered = [];
      angular.forEach(items, function(i) {
        if (ExploitService.meetsPrerequisites(character, i)) {
          filtered.push(i);
        }
      });
      return filtered;
    }
  }])
  .controller('ExploitsCtrl', function ExploitsCtrl($scope, $rootScope) {

      $scope.testData = {
          skill: {
              piloting: 1,
              anatomy: 1
          },
          attribute: {
              agi: 8,
              log: 7
          },
          exploit: {
              aim: 1
          }
      };

    var KEY = $scope.KEY = 'exploits';

    $scope.exploitsHash = $scope.$parent.exploitsHash;

    $scope.localExploits = [];
    angular.copy($scope.exploits, $scope.localExploits);

    if ($scope.character.exploits === undefined) {
      $scope.character.exploits = [];
    }

    angular.forEach($scope.character.exploits, function(c) {
      deleteFromArray($scope.localExploits, c);
    });

    $scope.addItem = function(c) {
      $scope.character.exploits.push(c);
      deleteFromArray($scope.localExploits, c);
    };

    $scope.chooseAimOrFeint = function(chosen, removed) {
        if($scope.hasExploit(chosen)) return;

        $scope.addItem(_.find($scope.localExploits, { Exploit: chosen }));

        if($scope.hasExploit(removed)) {
            $scope.removeItem(_.find($scope.character.exploits, { Exploit: removed }));
        }
    };

    $scope.canChoose = function(c) {
        if(c.Exploit === 'Aim' && $scope.hasExploit('Feint')) return false;
        if(c.Exploit === 'Feint' && $scope.hasExploit('Aim')) return false;
        return true;
    };

    $scope.hasExploit = function(name) {
        return _.find($scope.character.exploits, { Exploit: name });
    };

    $scope.isRaceExploit = function(exploit) { return _.contains($rootScope.raceExploits || [], exploit); };
    $scope.isCareerExploit = function(exploit) { return _.contains($rootScope.careerExploitStrings || [], exploit); };
    $scope.isAgeExploit = function(exploit) { return _.contains(['Young', 'Old'], exploit); };

    // TODO: delete this - i believe this is obsolete, given that you can just pay for extra exploits now
    // $scope.canAddMoreItems = function() {
    //     var allExploits = $scope.character.exploits;
    //     var ignoredExploits = $rootScope.raceExploits || [];
    //     var exploitsCount = 0;
    //     _.each(allExploits, function(exploit) {
    //         if(!exploit || _.contains(ignoredExploits, exploit.Exploit)) return;
    //         exploitsCount++;
    //     });
    //
    //     return exploitsCount < 4;
    // };

    $scope.removeItem = function(c) {
      $scope.localExploits.push(c);
      deleteFromArray($scope.character.exploits, c);
    };

    $scope.universalExploits = function() {
      return $scope.character.getUniversalExploits();
    };
    
    $scope.xpCost = function() {
      return $scope.character.calculateExploitXpCost($scope.universalExploits());
    };

  });