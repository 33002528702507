angular = require('angular');

angular.module('woin-character')
  .service('ExploitService', ['$rootScope', 'StatCalc', function($rootScope, StatCalc) {

    var meetsCareerRequirements = function(character, exploit, exploitData) {
      var meetsRequirements = true;
      if (exploitData[exploit] !== undefined && exploitData[exploit].career_exploit_prereq !== undefined) {
        var exploitRequirements = exploitData[exploit].career_exploit_prereq.split(',');
        if (exploitRequirements !== undefined) {
          if (_.find(character.exploits, { Exploit: exploit })) {
            meetsRequirements = false;
          } else {
            angular.forEach(exploitRequirements, function(requirement) {
              if (requirement !== undefined && requirement !== '') {
                meetsRequirements = meetsRequirements &&  _.find(character.exploits, { Exploit: requirement })
              }
            });
          }
        }
      }
      return meetsRequirements;
    };


    var attributePrereq = function(attribute, value) {
      return {
        meets: function(character) {
          var meet = (StatCalc.calcStat(attribute.toUpperCase()) >= value);
          console.log("Character meets attribute: " + attribute + " > " + value + "=? " + meet);
          return meet;
        }
      }
    }

    var skillPrereq = function(skillName) {
      return {
        meets: function(character) {
          var found = _.find(character.skills, function(skill) {
            if (skill.Skill !== undefined) {
              return (skill.Skill.toLowerCase() === skillName);
            } else if (skill.name !== undefined) {
              return (skill.name.toLowerCase() === skillName);
            } else {
              return false;
            }
          });
          console.log("Character meets skill: " + skillName + "? : " + found);
          return (found !== undefined);
        }
      }
    }

    var exploitPrereq = function(exploitName) {
      return {
        meets: function(character) {
          var found = _.find(character.exploits, function(exploit) {
            return (exploit.Exploit.toLowerCase() === exploitName);
          });
          console.log("Character meets skill: " + exploitName + "? : " + found);
          return (found !== undefined);
        }
      }
    };

    var loadPrerequisite = function(token) {
      console.log("Parsing prereq:" + token)
      var tokens = token.split(':');
      var prereq;
      switch (tokens[0]) {
        case "attribute":
          prereq = attributePrereq(tokens[1],tokens[2]);
          break;
        case "skill":
          prereq = skillPrereq(tokens[1]);
          break;
        case "exploit":
          prereq = exploitPrereq(tokens[1]);
          break;
        default:
          console.log("No valid prereq found: " + tokens[0]);
      }
      return prereq;
    };

    var getPrerequisitesFromExploit = function(exploit) {
      var prereqs = [];
      if (exploit.prereq !== undefined) {
        var tokens = [exploit.prereq];
        if (exploit.prereq.indexOf(';') >= 0) {
          tokens = exploit.prereq.split(';');
        }
        angular.forEach(tokens, function(token) {
          prereqs.push(loadPrerequisite(token));
        })
      }
      return prereqs;
    };

    var characterHasExploit = function(character, exploit) {
      return _.find(character.exploits, { Exploit: exploit.Exploit });
    };

    var meetsPrerequisites = function(character, exploit) {
      if (characterHasExploit(character, exploit)) {
        return false;
      } else {
        var prerequisites = getPrerequisitesFromExploit(exploit);
        var meetsPrereq = true;
        angular.forEach(prerequisites, function(prereq) {
          if (prereq !== undefined) {
            meetsPrereq = meetsPrereq && prereq.meets(character);
          }
        })
        return meetsPrereq;
      }
    };

    return {
      meetsCareerRequirements: meetsCareerRequirements,
      meetsPrerequisites: meetsPrerequisites
    };
  }])